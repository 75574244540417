import $ from 'jquery';

import Cookies from 'js-cookie';
import 'whatwg-fetch';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import firebaseConfig from './firebaseConfig';

if (!firebase.apps.length) { firebase.initializeApp(firebaseConfig); firebase.analytics(); }
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);

const urlContainsToken = () => window.location.href.indexOf('token=') >= 0;

if (urlContainsToken()) {
  const email = window.localStorage.getItem('emailForSignIn');
  if (!email) {
    $('#submitEmailButton').text('Email Adresse bestätigen');
  } else signInWithEmailLink(email);
} else {
  $('#submitEmailButton').text('Weiter');
}

$('#loginForm').submit(e => {
  e.preventDefault();

  const email = $('#emailInput').val().trim();
  if (urlContainsToken()) { // complete login
    if (emailIsValid(email)) signInWithEmailLink(email);
    else {
      let errorMsg = 'Die eingegebene Email Adresse ist ungültig.';
      if (email.length == 0) errorMsg = 'Bitte gib deine Email Adresse ein.';
      $('#fehlermeldung').text(errorMsg).removeClass('hidden');
    }
    return;
  }

  if (!emailIsValid(email)) {
    $('#fehlermeldung').text('Die eingegebene Email Adresse ist ungültig.').removeClass('hidden');
  } else {
    $('#sending-info').removeClass('hidden');
    $('#input-form').addClass('hidden');

    fetch('/api/login', {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
        'CSRF-Token': Cookies.get('XSRF-TOKEN'),
      },
    })
        .then(res => new Promise((resolve, reject) => {
          res.text()
              .then(responseText => resolve({ res, responseText }))
              .catch(err => reject(err));
        }))
        .then(({ res, responseText }) => {
          console.log(res);
          $('#sending-info').addClass('hidden');

          if (res.status == 200) {
            $('#emailRecipient').text(email);
            $('#waiting-info').removeClass('hidden');
            window.localStorage.setItem('emailForSignIn', email);
          } else {
            console.log(`status: ${res.status}`);
            $('#input-form').removeClass('hidden');
            $('#fehlermeldung').text(responseText || 'Leider ist ein unerwarteter Fehler aufgetreten.').removeClass('hidden');
            console.log('should show error message');
          }
        })
        .catch(err => {
          console.error(err);
          $('#fehlermeldung').text('Leider ist ein unerwarteter Fehler aufgetreten. Bitte versuchen Sie es später erneut.').fadeIn();
          $('#sending-info').addClass('hidden');
          $('#input-form').removeClass('hidden');
        });
  }
});

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function signInWithEmailLink(email = '') {
  const match = window.location.href.match(/^.*\/login\?.*token=(.*)/i);
  if (!match || match.length < 2 || !match[1]) { console.error('notoken'); return; }
  const token = match[1];

  fetch('/api/authToken', {
    method: 'POST',
    body: JSON.stringify({ token, email }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'CSRF-Token': Cookies.get('XSRF-TOKEN'),
    },
  })
      .then(res => new Promise((resolve, reject) => res.json().then(responseJson => resolve({ res, responseJson })).catch(err => reject(err))))
      .then(({ res, responseJson }) => {
        console.log(res);
        console.log(responseJson);
        if (res.status == 200 && responseJson.token) {
          firebase.auth().signInWithCustomToken(responseJson.token)
              .then(() => {
                window.localStorage.removeItem('emailForSignIn');
                return firebase.auth().currentUser.getIdToken();
              })
              .then(idToken => fetch('/api/sessionLogin', {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  'CSRF-Token': Cookies.get('XSRF-TOKEN'),
                },
                body: JSON.stringify({ idToken }),
              }))
              .then(() => {
                window.location.assign('/dashboard');
              })
              .catch(err => {
                console.error(err);
                $('#fehlermeldung').text('Leider ist ein unerwarteter Fehler aufgetreten.').fadeIn();
              });
        } else {
          $('#fehlermeldung').text(responseJson.message || 'Leider ist ein unerwarteter Fehler aufgetreten.').fadeIn();
        }
      })
      .catch(err => {
        console.error(err);
        $('#fehlermeldung').text('Leider ist ein unerwarteter Fehler aufgetreten.').fadeIn();
      });
}

$('#emailInput').on('input', () => {
  $('#emailInput').val($('#emailInput').val().trim());
  $('#fehlermeldung').addClass('hidden');
});
